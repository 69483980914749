export enum ExternalServiceGUID {
	ACCOUNTING_1C_CMS = '1C_ACCOUNTING_CMS',
	ALFA_BANK_BNK_CMS = 'ALFA_BANK_BNK_CMS',
	MODULE_BANK_BNK_CMS = 'MODULE_BANK_BNK_CMS',
	OPEN_BANK_BNK_CMS = 'OPEN_BANK_BNK_CMS',
	OZON_INSTANCE = 'OZON_INSTANCE',
	SBERBANK_BNK_CMS = 'SBERBANK_BNK_CMS',
	SBERBANK_BNK_CMS_EMAIL = 'SBERBANK_BNK_CMS_EMAIL',
	TINKOFF_BANK_BNK_CMS = 'TINKOFF_BANK_BNK_CMS',
	TOCHKA_BANK_BNK_CMS = 'TOCHKA_BANK_BNK_CMS',
	URALSIB_BNK_CMS = 'URALSIB_BNK_CMS',
	VTB_BANK_CMS = 'VTB_BANK_CMS',
	WILDBERRIES_INSTANCE = 'WILDBERRIES_INSTANCE',
	YOU_KASSA_CMS = 'YOU_KASSA_CMS',
	ZEN_MONEY_CMS = 'ZEN_MONEY_CMS',
}

export enum SberbankUserType {
	TOKEN = 'Token',
	WEB = 'WEB',
}
