import { createAsyncSelector, createSelector } from '@flux';
import { ProviderRole } from '@integrations/models';
import { IAppState } from '@store';
import { compose } from '@utils/helpers';
import { createObjectMap } from '@utils/object';
import { sortDescBy } from '@utils/sorting';

const selectAsyncCashManagementSystems = createAsyncSelector<Array<CashManagementSystem>, IAppState>({
	get: s => s.integrations.main.cashManagementSystems,
	selector: createSelector(
		s => s.integrations.main.cashManagementSystems.item,
		item => item,
	),
});

const selectAsyncExternalSystemAccounts = createAsyncSelector<Array<ExternalSystemAccount>, IAppState>({
	get: s => s.integrations.main.externalSystemAccounts,
	selector: createSelector(
		s => s.integrations.main.externalSystemAccounts.item,
		item => item,
	),
});

const selectAsyncRemoteExternalServicesMap = createAsyncSelector<Record<string, RemoteExternalService>, IAppState>({
	get: s => s.integrations.main.remoteExternalServicesMap,
	selector: createSelector(
		s => s.integrations.main.remoteExternalServicesMap.item,
		item => item,
	),
});

const selectAsyncSubSystemInstances = createAsyncSelector<Array<SubsystemInstance>, IAppState>({
	get: s => s.integrations.main.subSystemInstances,
	selector: createSelector(
		s => s.integrations.main.subSystemInstances.item,
		item => item,
	),
});

const selectAsyncLastAccountStatementImports = createAsyncSelector<Array<AccountStatementImport>, IAppState>({
	get: s => s.integrations.main.lastAccountStatementImports,
	selector: createSelector(
		s => s.integrations.main.lastAccountStatementImports.item,
		item => item,
	),
});

const selectAsyncSourceTypes = createAsyncSelector<Array<FundsRegisterSourceType>, IAppState>({
	get: s => s.integrations.main.sourceTypes,
	selector: createSelector(
		s => s.integrations.main.sourceTypes.item,
		item => item,
	),
});

const selectRemoteExternalServicesBySubSystemInstanceIdMap = createSelector(
	selectAsyncRemoteExternalServicesMap.selectItem,
	items => items,
);

const selectSubSystemInstancesMap = createSelector(selectAsyncSubSystemInstances.selectItem, items =>
	createObjectMap(items, x => x.ID),
);

const selectSubSystemInstancesByGuidMap = createSelector(selectAsyncSubSystemInstances.selectItem, items =>
	createObjectMap(items, x => x.GUID),
);

const selectCashManagementSystemsMap = createSelector(selectAsyncCashManagementSystems.selectItem, items =>
	createObjectMap(items, x => x.ID),
);

const selectCashManagementSystemsByGUIDMap = createSelector(selectAsyncCashManagementSystems.selectItem, items =>
	createObjectMap(items, x => x.SubsystemInstanceGUID),
);

const selectLastAccountStatementImportsMap = createSelector(selectAsyncLastAccountStatementImports.selectItem, items =>
	createObjectMap(items, x => x.FundsRegisterID),
);

const selectExternalSystemAccountsMap = createSelector(selectAsyncExternalSystemAccounts.selectItem, items =>
	createObjectMap(items, x => x.ID),
);

const selectSelectedCmsID = (state: IAppState) => state.integrations.main.selectedCmsID;

const selectSelectedCMS = createSelector(selectCashManagementSystemsMap, selectSelectedCmsID, (items, ID) => items[ID]);

const selectSelectedExternalSystemAccountID = (state: IAppState) =>
	state.integrations.main.selectedExternalSystemAccountID;

const selectSelectedExternalSystemAccount = createSelector(
	selectExternalSystemAccountsMap,
	selectSelectedExternalSystemAccountID,
	(items, ID) => items[ID],
);

const selectIsDataFetching = (state: IAppState) =>
	mainIntegrationsSelectorsPack.selectAsyncRemoteExternalServicesMap.selectIsFetching(state) ||
	mainIntegrationsSelectorsPack.selectAsyncExternalSystemAccounts.selectIsFetching(state);

const selectIsDataLoaded = (state: IAppState) =>
	mainIntegrationsSelectorsPack.selectAsyncRemoteExternalServicesMap.selectIsLoaded(state) &&
	mainIntegrationsSelectorsPack.selectAsyncExternalSystemAccounts.selectIsLoaded(state);

const selectFilteredExternalSystemAccounts = createSelector(
	selectAsyncExternalSystemAccounts.selectItem,
	selectSearchText,
	(sourceSccounts, sourceSearchText) => {
		const searchText = sourceSearchText.toLocaleLowerCase().trim();
		const accounts = compose<Array<ExternalSystemAccount>>(
			(accounts: Array<ExternalSystemAccount>) =>
				sortDescBy(accounts, [{ fn: x => x.ExternalSSInstanceID }, { fn: x => x.ID }]),
			(accounts: Array<ExternalSystemAccount>) =>
				searchText
					? accounts.filter(x => {
							const detectIsMatchName = () => x.Name.toLocaleLowerCase().indexOf(searchText) !== -1;
							const detectIsMatchServiceName = () =>
								x.ExternalSSInstanceName.toLocaleLowerCase().indexOf(searchText) !== -1;
							const detectIsMatchTaxCode = () =>
								x.TaxCode ? x.TaxCode.toLocaleLowerCase().indexOf(searchText) !== -1 : false;
							const detectIsMatchGUID = () => (x.GUID ? x.GUID.toLocaleLowerCase().indexOf(searchText) !== -1 : false);

							return detectIsMatchName() || detectIsMatchServiceName() || detectIsMatchTaxCode() || detectIsMatchGUID();
					  })
					: accounts,
		)(sourceSccounts);

		return accounts;
	},
);

const selectNotConnectedSubSystemInstances = createSelector(
	selectAsyncExternalSystemAccounts.selectItem,
	selectAsyncSubSystemInstances.selectItem,
	selectAsyncRemoteExternalServicesMap.selectItem,
	(accounts, ssiList, resMap) => {
		const accountsMap = createObjectMap(accounts, x => x.ExternalSSInstanceID);
		const filteredSsiList = ssiList
			.filter(x => (resMap[x.ID]?.Roles || []).some(x => x.CLASSIFIER === ProviderRole.DATA_PROVIDER_ROLE))
			.filter(x => !accountsMap[x.ID]);

		return filteredSsiList;
	},
);

const selectExternalSystemAccountsByCMS = createSelector(
	selectSelectedCMS,
	selectAsyncExternalSystemAccounts.selectItem,
	selectAsyncSubSystemInstances.selectItem,
	(CMS, systemAccounts, subsystemInstanses) => {
		let filteredAccounts: Array<ExternalSystemAccount> = [];

		if (CMS) {
			const filteredCMS = subsystemInstanses.find(x => x.GUID === CMS.SubsystemInstanceGUID);
			filteredAccounts = systemAccounts.filter(x => x.ExternalSSInstanceID === filteredCMS?.ID);
		}

		return filteredAccounts;
	},
);

const selectFilteredExternalSystemAccountsByCMS = createSelector(
	selectExternalSystemAccountsByCMS,
	selectCmsAccountsTextFilter,
	(sourceAccounts, sourceSearchText) => {
		const searchText = sourceSearchText.toLowerCase().trim();
		const filteredAccounts = compose<Array<ExternalSystemAccount>>((filteredAccounts: Array<ExternalSystemAccount>) =>
			sourceSearchText
				? filteredAccounts.filter(x => {
						const detectIsMatchName = () => (x.Name ? x.Name.toLowerCase().indexOf(searchText) !== -1 : false);
						const detectIsMatchTaxCode = () => (x.TaxCode ? x.TaxCode.toLowerCase().indexOf(searchText) !== -1 : false);

						return detectIsMatchName() || detectIsMatchTaxCode();
				  })
				: filteredAccounts,
		)(sourceAccounts);

		return filteredAccounts;
	},
);

const selectImportDateRange = (state: IAppState) => state.integrations.main.importDateRange;

const selectLastImportedAccountData = (state: IAppState) => state.integrations.main.lastImportedAccountData;

const selectLastImportedFileData = (state: IAppState) => state.integrations.main.lastImportedFileData;

const selectLastAccountStatementImport = (state: IAppState) => state.integrations.main.lastAccountStatementImport;

const selectCMSCreatePaymentDraft = createSelector(selectAsyncCashManagementSystems.selectItem, CMSList => {
	const filtered = CMSList.filter((CMS: CashManagementSystem) => {
		return CMS.SupportCreatePaymentDraft || CMS.SubsystemInstanceGUID === 'SBERBANK_BNK_CMS'; //FOR TEST;
	});

	return createObjectMap(filtered, item => item.ID);
});

function selectSearchText(state: IAppState): string {
	return state.integrations.main.searchText;
}

function selectCmsAccountsTextFilter(state: IAppState) {
	return state.integrations.main.cmsAccountsTextFilter;
}

export const mainIntegrationsSelectorsPack = {
	selectAsyncCashManagementSystems,
	selectAsyncExternalSystemAccounts,
	selectAsyncLastAccountStatementImports,
	selectAsyncRemoteExternalServicesMap,
	selectAsyncSourceTypes,
	selectAsyncSubSystemInstances,
	selectCashManagementSystemsByGUIDMap,
	selectExternalSystemAccountsByCMS,
	selectExternalSystemAccountsMap,
	selectFilteredExternalSystemAccounts,
	selectImportDateRange,
	selectIsDataFetching,
	selectIsDataLoaded,
	selectLastAccountStatementImport,
	selectLastImportedAccountData,
	selectLastImportedFileData,
	selectNotConnectedSubSystemInstances,
	selectRemoteExternalServicesBySubSystemInstanceIdMap,
	selectSearchText,
	selectSelectedCMS,
	selectSelectedExternalSystemAccount,
	selectSelectedExternalSystemAccountID,
	selectSubSystemInstancesByGuidMap,
	selectSubSystemInstancesMap,
};

export {
	selectAsyncCashManagementSystems,
	selectAsyncExternalSystemAccounts,
	selectAsyncLastAccountStatementImports,
	selectAsyncRemoteExternalServicesMap,
	selectAsyncSourceTypes,
	selectAsyncSubSystemInstances,
	selectCashManagementSystemsMap,
	selectCmsAccountsTextFilter,
	selectCMSCreatePaymentDraft,
	selectExternalSystemAccountsByCMS,
	selectFilteredExternalSystemAccountsByCMS,
	selectImportDateRange,
	selectLastAccountStatementImport,
	selectLastAccountStatementImportsMap,
	selectLastImportedAccountData,
	selectLastImportedFileData,
	selectSelectedCMS,
	selectSelectedExternalSystemAccount,
	selectSelectedExternalSystemAccountID,
};
