import { compose } from 'redux';
import * as _ from 'underscore';

import { createAsyncSelector, createRightCheck, createSelector } from '@flux';
import { DirectionAccess, LevelAccess, ServerStatus } from '@funds-registers/models';
import { getFundsRegisterStatisticVirtualListGroupKey } from '@funds-registers/utils';
import {
	selectAsyncCashManagementSystems,
	selectAsyncExternalSystemAccounts,
	selectAsyncLastAccountStatementImports,
	selectAsyncSubSystemInstances,
} from '@integrations/selectors';
import { securitySelectorsPack } from '@platform/selectors';
import { rangedCMSNamesList, SBERBANK_BNK_CMS } from '@shared/constants/cms-guids';
import { selectCurrencyByIdMap } from '@shared/selectors/currency-list';
import { IAppState } from '@store';
import { mainTenantLegalEntitiesSelectorsPack } from '@tenant-legal-entities/selectors';
import {
	detectIsBankAccountFundsRegister,
	detectIsVirtualAccountFundsRegister,
	getFundsRegistersAccessFlags,
	getSum,
} from '@utils/finance';
import { createObjectMap } from '@utils/object';
import { sortAscBy } from '@utils/sorting';

const selectFundsRegistersRight = createRightCheck({
	view: 'xFundsRegisters_ViewFundsRegisters',
	change: 'xFundsRegisters_ChangeFundsRegisters',
});

const selectFundsRegistersAccessRight = createRightCheck({
	change: 'xFundsRegisters_ChangeFundsRegistersAccess',
});

const selectAsyncFundsRegisterStatistics = createAsyncSelector<Array<FundsRegisterStatistics>, IAppState>({
	get: s => s.fundsRegisters.main.fundsRegisterStatistics,
	selector: createSelector(
		s => s.fundsRegisters.main.fundsRegisterStatistics.item,
		item => item,
	),
});

const selectAsyncFundsRegisterPurposes = createAsyncSelector<Array<FundsRegisterPurpose>, IAppState>({
	get: s => s.fundsRegisters.main.purposes,
	selector: createSelector(
		s => s.fundsRegisters.main.purposes.item,
		item => item,
	),
});

const selectAsyncBalanceTotalKpiRefreshMessage = createAsyncSelector<SuccessMessage, IAppState>({
	get: s => s.fundsRegisters.main.balanceTotalKpiRefreshMessage,
	selector: createSelector(
		s => s.fundsRegisters.main.balanceTotalKpiRefreshMessage.item,
		item => item,
	),
});

const selectAsyncIsBalanceHidden = createAsyncSelector<boolean, IAppState>({
	get: s => s.fundsRegisters.main.isBalanceHidden,
	selector: createSelector(
		s => s.fundsRegisters.main.isBalanceHidden.item,
		item => item,
	),
});

const selectAsyncBanks = createAsyncSelector<Array<Bank>, IAppState>({
	get: s => s.fundsRegisters.main.banks,
	selector: createSelector(
		s => s.fundsRegisters.main.banks.item,
		item => item,
	),
});

const selectFundsRegisterStatisticsMap = createSelector(
	selectAsyncFundsRegisterStatistics.selectItem,
	fundsRegisterStatistics => createObjectMap(fundsRegisterStatistics, x => x.FundsRegister.ID),
);

const selectFundsRegisters = createSelector(selectAsyncFundsRegisterStatistics.selectItem, fundsRegisterStatistics =>
	fundsRegisterStatistics.map(x => x.FundsRegister),
);

const selectFundsRegistersMap = createSelector(selectFundsRegisters, fundsRegisters =>
	createObjectMap(fundsRegisters, x => x.ID),
);

const selectFundsRegistersByExternalSystemAccountsMap = createSelector(selectFundsRegisters, fundsRegisters =>
	createObjectMap(fundsRegisters, x => x.ExternalSystemAccountID),
);

const selectFundsRegistersFilteredByTenants = createSelector(
	selectFundsRegistersMap,
	(_, tenantEntityFilter: Record<number, boolean>) => tenantEntityFilter,
	(fundsRegistersMap, tenantEntityFilter) => {
		const hasTenantEntityFilter = tenantEntityFilter && Object.keys(tenantEntityFilter).length > 0;
		const filteredFrList = Object.keys(fundsRegistersMap)
			.map(key => fundsRegistersMap[key])
			.filter(x => (hasTenantEntityFilter ? tenantEntityFilter[x?.LegalEntity?.ID] : true))
			.filter(x => !x.Archived);
		const filteredFrMap = createObjectMap(filteredFrList, x => x.ID);

		return filteredFrMap;
	},
);

const selectTenantEntitiesWithFundsRegisters = createSelector(
	mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectItem,
	selectFundsRegisters,
	(tenantEntities, fundsRegisters) => {
		const groupedFrMap = _.groupBy(fundsRegisters, x => x?.LegalEntity?.ID || -1);
		const filteredTenantEntities = tenantEntities.filter(
			x => groupedFrMap[x.ID] && groupedFrMap[x.ID].some(x => !x.Archived),
		);

		return filteredTenantEntities;
	},
);

const selectTenantEntitiesWithFundsRegistersMap = createSelector(
	selectTenantEntitiesWithFundsRegisters,
	tenantEntities => createObjectMap(tenantEntities, x => x.ID),
);

const selectPurposeMap = createSelector(selectAsyncFundsRegisterPurposes.selectItem, purposes => {
	return createObjectMap(purposes, x => (x.NumOrder > 0 && x.NumOrder < 5 ? 5 - x.NumOrder : x.NumOrder));
});

function selectTextFilter(state: IAppState) {
	return state.fundsRegisters.main.textFilter;
}

function selectVirtualListGroup(state: IAppState) {
	return state.fundsRegisters.main.virtualListGroup;
}

function selectIsIncludeArchived(state: IAppState) {
	return state.fundsRegisters.main.isIncludeArchived;
}

const selectFilteredFundsRegisters = createSelector(
	selectFundsRegisters,
	selectTextFilter,
	selectIsIncludeArchived,
	selectVirtualListGroup,
	(sourceFundsRegisters, sourceSearchText, isIncludeArchived, virtualListGroup) => {
		const searchText = sourceSearchText.toLowerCase().trim();
		const isExcludeArchived = !isIncludeArchived;
		const fundsRegisters = compose(
			(fundsRegisters: Array<FundsRegister>) => {
				return sortAscBy(fundsRegisters, [
					{ fn: item => getFundsRegisterStatisticVirtualListGroupKey(virtualListGroup, item) },
					{ fn: item => (detectIsBankAccountFundsRegister(item) ? -1 : 1) },
					{ fn: item => item.ID },
				]);
			},
			(fundsRegisters: Array<FundsRegister>) =>
				sourceSearchText
					? fundsRegisters.filter((x: BankAccountFundsRegister) => {
							const detectIsMatchName = () => x.Name.toLowerCase().indexOf(searchText) !== -1;
							const detectIsMatchRegisterNumber = () => x.RegisterNumber.toLowerCase().indexOf(searchText) !== -1;
							const detectIsMatchBankName = () =>
								x.Bank?.Name ? x.Bank.Name.toLowerCase().indexOf(searchText) !== -1 : false;
							const detectIsMatchLegalEntityName = () =>
								x.LegalEntity?.Name ? x.LegalEntity.Name.toLowerCase().indexOf(searchText) !== -1 : false;

							return (
								detectIsMatchName() ||
								detectIsMatchRegisterNumber() ||
								detectIsMatchBankName() ||
								detectIsMatchLegalEntityName()
							);
					  })
					: fundsRegisters,
			(fundsRegisters: Array<FundsRegister>) =>
				isExcludeArchived ? fundsRegisters.filter(x => !x.Archived) : fundsRegisters,
		)(sourceFundsRegisters);

		return fundsRegisters;
	},
);

const selectActualFundsRegisterStatistics = createSelector(
	selectAsyncFundsRegisterStatistics.selectItem,
	fundsRegisterStatistics => {
		return fundsRegisterStatistics.filter(x => !x.FundsRegister.Archived);
	},
);

const selectFilteredFundsRegistersByAccessList = createSelector(
	selectFundsRegisters,
	securitySelectorsPack.selectIsTechService,
	(_, levelAccess: LevelAccess, directionAccess: DirectionAccess) => ({ levelAccess, directionAccess }),
	(fundsRegisters, isFullAccess, options) => {
		return fundsRegisters.filter(fundsRegister => {
			if (isFullAccess) return true;
			const { levelAccess, directionAccess } = options;
			const {
				hasReadAccessToIncome,
				hasReadAccessToExpense,
				hasReadAccessToAllDirections,
				hasWriteAccessToIncome,
				hasWriteAccessToExpense,
				hasWriteAccessToAllDirections,
			} = getFundsRegistersAccessFlags([fundsRegister]);
			const map: Record<DirectionAccess, Record<LevelAccess, () => boolean>> = {
				all: {
					write: () => hasWriteAccessToAllDirections,
					read: () => hasReadAccessToAllDirections,
				},
				receipt: {
					write: () => hasWriteAccessToIncome,
					read: () => hasReadAccessToIncome,
				},
				charge: {
					write: () => hasWriteAccessToExpense,
					read: () => hasReadAccessToExpense,
				},
			};

			return (
				(map[directionAccess] && map[directionAccess][levelAccess] && map[directionAccess][levelAccess]()) || false
			);
		});
	},
);

function selectIsFundsRegistersDataFetching(state: IAppState) {
	return (
		selectAsyncFundsRegisterStatistics.selectIsFetching(state) ||
		selectAsyncFundsRegisterPurposes.selectIsFetching(state) ||
		selectAsyncCashManagementSystems.selectIsFetching(state) ||
		mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectIsFetching(state)
	);
}

function selectIsFundsRegistersDataLoaded(state: IAppState) {
	return (
		selectAsyncFundsRegisterStatistics.selectIsLoaded(state) &&
		selectAsyncFundsRegisterPurposes.selectIsLoaded(state) &&
		selectAsyncCashManagementSystems.selectIsLoaded(state) &&
		mainTenantLegalEntitiesSelectorsPack.selectAsyncTenantLegalEntities.selectIsLoaded(state)
	);
}

function selectIsCmsConnectDataFetching(state: IAppState) {
	return (
		selectAsyncFundsRegisterStatistics.selectIsFetching(state) ||
		selectAsyncFundsRegisterPurposes.selectIsFetching(state) ||
		selectAsyncCashManagementSystems.selectIsFetching(state) ||
		selectAsyncExternalSystemAccounts.selectIsFetching(state) ||
		selectAsyncSubSystemInstances.selectIsFetching(state)
	);
}

const extractNames = (map: Record<string, { Name: string }>) => {
	return Object.keys(map).reduce((acc, key) => ((acc[key] = map[key].Name), acc), {});
};

const selectVirtualGroupProps = createSelector(
	selectVirtualListGroup,
	mainTenantLegalEntitiesSelectorsPack.selectTenantLegalEntitiesMap,
	selectPurposeMap,
	selectCurrencyByIdMap,
	(groupCode, tenantEntityMap, purposeMap, currencyByIDMap) => {
		const tenantEntitiesNamesMap = extractNames(tenantEntityMap as any);
		const purposesNamesMap = extractNames(purposeMap);
		const currenciesNamesMap = extractNames(currencyByIDMap);
		const data = {
			groupCode,
			tenantEntitiesNamesMap,
			purposesNamesMap,
			currenciesNamesMap,
		};

		return data;
	},
);

const selectBankAccountAmount = createSelector(selectAsyncFundsRegisterStatistics.selectItem, fundsRegisters => {
	return fundsRegisters.filter(x => detectIsBankAccountFundsRegister(x.FundsRegister)).length || 0;
});

const selectCashDeskAmount = createSelector(selectAsyncFundsRegisterStatistics.selectItem, fundsRegisters => {
	return fundsRegisters.filter(x => detectIsVirtualAccountFundsRegister(x.FundsRegister)).length || 0;
});

const selectSupportedCMS = createSelector(selectAsyncCashManagementSystems.selectItem, CMSList => {
	const rangeMap = rangedCMSNamesList.reduce((acc, v, idx) => ((acc[v] = idx), acc), {});
	const list = [...CMSList].sort((a, b) => rangeMap[a.SubsystemInstanceGUID] - rangeMap[b.SubsystemInstanceGUID]);
	return list;
});

const selectBankAccountByCmsStats = createSelector(
	selectAsyncFundsRegisterStatistics.selectItem,
	selectAsyncCashManagementSystems.selectItem,
	(statistics, cmsList) => {
		const map: Record<string, number> = {};

		cmsList.forEach(cms => {
			map[cms.ID] = 0;
			statistics.forEach(x => {
				const fundsRegister = x.FundsRegister;

				if (fundsRegister.CashManagementSystem && fundsRegister.CashManagementSystem.ID === cms.ID) {
					map[cms.ID]++;
				}
			});
		});

		return map;
	},
);

const selectFundsRegisterIDForRefresh = (state: IAppState) => state.fundsRegisters.main.fundsRegisterIDForRefresh;

const selectFundsRegisterIDForAutoInsert = (state: IAppState) => state.fundsRegisters.main.fundsRegisterIDForAutoInsert;

const selectFundsRegisterForRefresh = createSelector(
	selectFundsRegisterIDForRefresh,
	selectFundsRegistersMap,
	(ID, fundsRegister) => fundsRegister[ID],
);

const selectFundsRegisterForAutoInsert = createSelector(
	selectFundsRegisterIDForAutoInsert,
	selectFundsRegistersMap,
	(ID, fundsRegister) => fundsRegister[ID] as BankAccountFundsRegister,
);

const selectBalanceTotal = createSelector(selectAsyncFundsRegisterStatistics.selectItem, frsMap => {
	const fundsRegisterStatistics = Object.keys(frsMap)
		.map(key => frsMap[key])
		.filter(x => !x.FundsRegister.Archived);
	const totalOutgoingBalance = getSum(fundsRegisterStatistics, x => x.OutgoingBalance);

	return totalOutgoingBalance;
});

const selectFirstAccountStatementImportInProgress = createSelector(
	selectAsyncLastAccountStatementImports.selectItem,
	lastAccountStatementImports => {
		const asiInProgress = lastAccountStatementImports.find(
			item => item.ImportStatusCode === ServerStatus.IN_PROGRESS || item.ImportStatusCode === ServerStatus.WAITING,
		);

		return asiInProgress;
	},
);

const selectFirstAccountStatementImportFailed = createSelector(
	selectAsyncLastAccountStatementImports.selectItem,
	selectFundsRegisterStatisticsMap,
	(lastAccountStatementImports, fundsRegisterStatisticsMapMap) => {
		const lastAccountStatementImportsFailed = lastAccountStatementImports
			.filter(x => !fundsRegisterStatisticsMapMap[x.FundsRegisterID]?.FundsRegister?.Archived)
			.find(
				item =>
					item.ImportStatusCode === ServerStatus.FAILED || item.ImportStatusCode === ServerStatus.FINISHED_WITH_WARNING,
			);

		return lastAccountStatementImportsFailed;
	},
);

const selectIsAnyAccountStatementImportInProgress = createSelector(
	selectAsyncLastAccountStatementImports.selectItem,
	lastAccountStatementImports => {
		const isAnyAsiInProgress = !!lastAccountStatementImports.find(
			item => item.ImportStatusCode === ServerStatus.IN_PROGRESS || item.ImportStatusCode === ServerStatus.WAITING,
		);

		return isAnyAsiInProgress;
	},
);

const selectFrWithCMSCreatePaymentDraft = createSelector(
	selectFundsRegisters,
	selectAsyncCashManagementSystems.selectItem,
	(fundsRegisters, cmsList) => {
		const filtered = fundsRegisters.filter(x => {
			const fundsRegister = x as BankAccountFundsRegister;
			const isBankAccount = detectIsBankAccountFundsRegister(fundsRegister);
			const ssiGUID = fundsRegister?.CashManagementSystem?.SubsystemInstanceGUID || '';
			const CMS = cmsList.find(x => x.SubsystemInstanceGUID === ssiGUID);
			const isConnectedToCMS = Boolean(fundsRegister.CashManagementSystem);
			const hasLegalEntity = fundsRegister?.LegalEntity?.ID || -1 > 0;
			const supportCreatePaymentDraft =
				CMS?.SubsystemInstanceGUID === SBERBANK_BNK_CMS || CMS?.SupportCreatePaymentDraft || false;

			return isBankAccount && isConnectedToCMS && hasLegalEntity && supportCreatePaymentDraft;
		});

		return _.indexBy(filtered, item => item.ID);
	},
);

export const mainFundsRegistersSelectorsPack = {
	selectActualFundsRegisterStatistics,
	selectAsyncBalanceTotalKpiRefreshMessage,
	selectAsyncBanks,
	selectAsyncFundsRegisterPurposes,
	selectAsyncFundsRegisterStatistics,
	selectAsyncIsBalanceHidden,
	selectBalanceTotal,
	selectBankAccountAmount,
	selectBankAccountByCmsStats,
	selectCashDeskAmount,
	selectFilteredFundsRegisters,
	selectFilteredFundsRegistersByAccessList,
	selectFirstAccountStatementImportFailed,
	selectFundsRegisterForAutoInsert,
	selectFundsRegisterForRefresh,
	selectFundsRegisters,
	selectFundsRegistersAccessRight,
	selectFundsRegistersMap,
	selectFundsRegistersRight,
	selectFundsRegisterStatisticsMap,
	selectIsAnyAccountStatementImportInProgress,
	selectIsCmsConnectDataFetching,
	selectIsFundsRegistersDataFetching,
	selectIsFundsRegistersDataLoaded,
	selectIsIncludeArchived,
	selectSupportedCMS,
	selectTextFilter,
	selectVirtualGroupProps,
	selectVirtualListGroup,
};

export {
	selectActualFundsRegisterStatistics,
	selectAsyncBalanceTotalKpiRefreshMessage,
	selectAsyncBanks,
	selectAsyncFundsRegisterPurposes,
	selectAsyncFundsRegisterStatistics,
	selectAsyncIsBalanceHidden,
	selectBalanceTotal,
	selectBankAccountAmount,
	selectFilteredFundsRegisters,
	selectFirstAccountStatementImportFailed,
	selectFirstAccountStatementImportInProgress,
	selectFrWithCMSCreatePaymentDraft,
	selectFundsRegisterForAutoInsert,
	selectFundsRegisterForRefresh,
	selectFundsRegisters,
	selectFundsRegistersAccessRight,
	selectFundsRegistersByExternalSystemAccountsMap,
	selectFundsRegistersFilteredByTenants,
	selectFundsRegistersMap,
	selectFundsRegistersRight,
	selectFundsRegisterStatisticsMap,
	selectIsAnyAccountStatementImportInProgress,
	selectSupportedCMS,
	selectTenantEntitiesWithFundsRegisters,
	selectTenantEntitiesWithFundsRegistersMap,
};
