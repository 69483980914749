import { checkAsyncAction, createAsyncInitialState, createReducer, invalidateStateFromAction } from '@flux';
import { createDefaultPeriod } from '@utils/date';
import { types } from '../actions/types';

export type MainReconciliationActState = {
	basis: string | null;
	client: CounterpartyBrief | null;
	clientEmployee: EmployeeBrief | null;
	company: CounterpartyBrief | null;
	companyEmployee: EmployeeBrief | null;
	dateRange: DateRange;
	reconciliationAct: StoreAsyncItem<ReconciliationAct>;
};

const initialState: MainReconciliationActState = {
	basis: 'Без документа-основания',
	client: null,
	clientEmployee: null,
	company: null,
	companyEmployee: null,
	dateRange: createDefaultPeriod('year'),
	reconciliationAct: createAsyncInitialState(null),
};

const mainReconciliationActReducer = createReducer<MainReconciliationActState>(initialState, {
	[types.FETCH_RECONCILIATION_ACT]: (action: AsyncAction<ReconciliationAct>, state) => {
		return {
			reconciliationAct: checkAsyncAction(action, state.reconciliationAct),
		};
	},
	[types.SET_BASIS]: (action: StaticAction<string>) => {
		return {
			basis: action.value,
		};
	},
	[types.SET_CLIENT]: (action: StaticAction<CounterpartyBrief>, state) => {
		return {
			client: action.value,
			clientEmployee: null,
			reconciliationAct: Boolean(action.value)
				? invalidateStateFromAction(action, state.reconciliationAct)
				: createAsyncInitialState(null),
		};
	},
	[types.SET_CLIENT_EMPLOYEE]: (action: StaticAction<EmployeeBrief>) => {
		return {
			clientEmployee: action.value,
		};
	},
	[types.SET_COMPANY]: (action: StaticAction<CounterpartyBrief>, state) => {
		return {
			company: action.value,
			companyEmployee: null,
			reconciliationAct: Boolean(action.value)
				? invalidateStateFromAction(action, state.reconciliationAct)
				: createAsyncInitialState(null),
		};
	},
	[types.SET_COMPANY_EMPLOYEE]: (action: StaticAction<EmployeeBrief>) => {
		return {
			companyEmployee: action.value,
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			reconciliationAct: invalidateStateFromAction(action, state.reconciliationAct),
		};
	},
});

export default mainReconciliationActReducer;
