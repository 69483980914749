import { FetchIntegrationsDataReturnType } from '@core/api/security';
import { IntegrationsAuthOptions } from '@core/api/sso';
import { createAsyncAction, createStaticAction, RECEIVE } from '@flux';
import { FileAppearance } from '@funds-registers/components/import-files-form/model';
import {
	selectAsyncCashManagementSystems,
	selectAsyncExternalSystemAccounts,
	selectAsyncLastAccountStatementImports,
	selectAsyncRemoteExternalServicesMap,
	selectAsyncSourceTypes,
} from '@integrations/selectors';
import { selectTicketUID } from '@platform/selectors/context.selectors';
import { createNotificationMessage } from '@utils/notifications';
import { types } from './types';

const actions = {
	addExternalSystemAccount: createAsyncAction(
		types.ADD_EXTERNAL_SYSTEM_ACCOUNT,
		(api, getState, __, options: AddExternalSystemAccountOptions) => {
			const { account, authOptions, onComplete = () => {} } = options;

			return new Promise<ExternalSystemAccount | null>(resolve => {
				if (authOptions) {
					const ticketUID = selectTicketUID(getState());

					api.ssoPack.integrations
						.auth({
							...authOptions,
							ticketUID,
							externalSystemAccountID: -1,
						})
						.then(resolve);
				} else {
					api.securityPack.externalSystem.addExternalSystemAccount(account).then(result => {
						resolve(result);
						onComplete(result);
					});
				}
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Учётная запись добавлена 😊', 'success'),
		},
	) as (options: AddExternalSystemAccountOptions) => void,
	authorizeExternalSystemAccount: createAsyncAction(
		types.AUTHORIZE_EXTERNAL_SYSTEM_ACCOUNT,
		(api, getState, __, options: AuthOptions) => {
			const ticketUID = selectTicketUID(getState());

			return api.ssoPack.integrations.auth({ ...options, ticketUID });
		},
	) as (options: AuthOptions) => void,
	clearLastImportedAccountData: () => createStaticAction(types.CLEAR_LAST_IMPORTED_ACCOUNT_DATA)(),
	clearLastImportedFileData: () => createStaticAction(types.CLEAR_LAST_IMPORTED_FILE_DATA)(),
	fetchCashManagementSystems: createAsyncAction(
		types.FETCH_CASH_MANAGEMENT_SYSTEMS,
		api => {
			return new Promise<Array<CashManagementSystem>>(resolve => {
				api.fundsPack.cashManagementSystem.fetchCashManagementSystems().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncCashManagementSystems.selectIsValid,
			isFetchingSelector: selectAsyncCashManagementSystems.selectIsFetching,
		},
	) as () => void,
	fetchExternalSystemAccounts: createAsyncAction(
		types.FETCH_EXTERNAL_SYSTEM_ACCOUNTS,
		api => {
			return new Promise<Array<ExternalSystemAccount>>(resolve => {
				api.securityPack.externalSystem.fetchExternalSystemAccounts().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncExternalSystemAccounts.selectIsValid,
			isFetchingSelector: selectAsyncExternalSystemAccounts.selectIsFetching,
		},
	) as () => void,
	fetchFundsRegisterSourceTypes: createAsyncAction(
		types.FETCH_SOURCE_TYPES,
		api => {
			return new Promise<Array<FundsRegisterSourceType>>(resolve => {
				api.fundsPack.fundsRegister.fetchFundsRegisterSourceTypes().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncSourceTypes.selectIsValid,
			isFetchingSelector: selectAsyncSourceTypes.selectIsFetching,
		},
	) as () => void,
	fetchIntegrationsData: createAsyncAction(
		types.FETCH_INTEGRATIONS_DATA,
		api => {
			return new Promise<FetchIntegrationsDataReturnType>(resolve => {
				api.securityPack.externalSystem.fetchIntegrationsData().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncRemoteExternalServicesMap.selectIsValid,
			isFetchingSelector: selectAsyncRemoteExternalServicesMap.selectIsFetching,
		},
	) as () => void,
	fetchLastAccountStatementImports: createAsyncAction(
		types.FETCH_LAST_ACCOUNT_STATEMENT_IMPORTS,
		api => {
			return new Promise<Array<AccountStatementImport>>(resolve => {
				api.fundsPack.fundsRegister.fetchLastAccountStatementImports().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: selectAsyncLastAccountStatementImports.selectIsValid,
			isFetchingSelector: selectAsyncLastAccountStatementImports.selectIsFetching,
		},
	) as () => void,
	removeExternalSystemAccount: createAsyncAction(
		types.REMOVE_EXTERNAL_SYSTEM_ACCOUNT,
		(api, _, __, ID: number) => {
			return new Promise<boolean>(resolve => {
				api.securityPack.externalSystem.removeExternalSystemAccount(ID).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Учётная запись удалена 😊', 'success'),
		},
	) as (ID: number) => void,
	setCmsAccountsTextFilter: (value: string) => createStaticAction(types.SET_CMS_ACCOUNTS_TEXT_FILTER)(value),
	setImportDateRange: (value: DateRange) => createStaticAction(types.SET_IMPORT_DATE_RANGE)(value),
	setLastAccountStatementImport: (value: AccountStatementImport) =>
		createStaticAction(types.SET_LAST_ACCOUNT_STATEMENT_IMPORT)(value),
	setLastImportedAccountData: (value: LastImportedAccountData) =>
		createStaticAction(types.SET_LAST_IMPORTED_ACCOUNT_DATA)(value),
	setLastImportedFileData: (value: LastImportedFileData) =>
		createStaticAction(types.SET_LAST_IMPORTED_FILE_DATA)(value),
	setSearchText: (value: string) => createStaticAction(types.SET_SEARCH_TEXT)(value),
	setSelectedCmsID: (value: number) => createStaticAction(types.SET_SELECTED_CMS_ID)(value),
	setSelectedExternalSystemAccountID: (value: number) =>
		createStaticAction(types.SET_SELECTED_EXTERNAL_SYSTEM_ACCOUNT_ID)(value),
	updateExternalSystemAccount: createAsyncAction(
		types.UPDATE_EXTERNAL_SYSTEM_ACCOUNT,
		(api, _, __, account: ExternalSystemAccount) => {
			return new Promise<ExternalSystemAccount>(resolve => {
				api.securityPack.externalSystem.updateExternalSystemAccount(account).then(result => {
					resolve(result);
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Учётная запись изменена 😊', 'success'),
		},
	) as (account: ExternalSystemAccount) => void,
};

type AddExternalSystemAccountOptions = {
	account: ExternalSystemAccount;
	authOptions?: AuthOptions;
	onComplete?: (account: ExternalSystemAccount) => void;
};

export type AuthOptions = Omit<IntegrationsAuthOptions, 'ticketUID'>;

export type LastImportedAccountData = {
	registerNumber: string;
	legalEntityName: string;
};

export type LastImportedFileData = {
	name?: string;
	appearance?: FileAppearance | null;
	fundsRegisterID?: number;
};

export const mainIntegrationsActionsPack = actions;
