import React, { useEffect, useMemo, useState } from 'react';

import { securityApi } from '@core/api/security';
import { useFormVariant } from '@core/hooks/use-form-variant';
import { useAutoFetch, useMapDispatch, useMapState } from '@flux';
import { AuthOptions, mainIntegrationsActionsPack } from '@integrations/actions';
import { AuthMethodCode } from '@integrations/models';
import { mainIntegrationsSelectorsPack } from '@integrations/selectors';
import { getAuthMethodCode } from '@integrations/utils';
import { selectTicketUID } from '@platform/selectors/context.selectors';
import { ROUTE_MAP } from '@routes/urls';
import { emitActionMessage as emitActionMessageAction } from '@shared/actions/action-message.actions';
import { getDrawer } from '@utils';
import { decryptVernam } from '@utils/crypto';
import { NON_UPDATED_PASSWORD } from './model';
import {
	UpdateForm as XUpdateForm,
	UpdateFormProps as XUpdateFormProps,
} from './update-external-system-account-form.view';

export type UpdateFormProps = {
	accountID?: number;
	postActionURL?: string;
	onAddAccount?: (account: ExternalSystemAccount) => void;
} & Pick<XUpdateFormProps, 'variant' | 'ssiGUID'> &
	Partial<Pick<XUpdateFormProps, 'embedded' | 'onClose'>>;

const UpdateForm: React.FC<UpdateFormProps> = props => {
	const { variant, accountID, ssiGUID, postActionURL, embedded, onAddAccount, onClose, ...rest } = props;
	const [isFetching, setIsFetching] = useState(true);
	const [account, setAccount] = useState<ExternalSystemAccount>(null);
	const [isFetchingFromState, resMap, ssiMap, ticketUID] = useMapState([
		mainIntegrationsSelectorsPack.selectAsyncRemoteExternalServicesMap.selectIsFetching,
		mainIntegrationsSelectorsPack.selectRemoteExternalServicesBySubSystemInstanceIdMap,
		mainIntegrationsSelectorsPack.selectSubSystemInstancesByGuidMap,
		selectTicketUID,
	]);
	const [
		fetchIntegrationsData,
		addExternalSystemAccount,
		updateExternalSystemAccount,
		authorizeExternalSystemAccount,
		emitActionMessage,
	] = useMapDispatch([
		mainIntegrationsActionsPack.fetchIntegrationsData,
		mainIntegrationsActionsPack.addExternalSystemAccount,
		mainIntegrationsActionsPack.updateExternalSystemAccount,
		mainIntegrationsActionsPack.authorizeExternalSystemAccount,
		emitActionMessageAction,
	]);
	const { isAdd, isEdit } = useFormVariant(variant);
	const scope = useMemo(() => ({ adminPassword: '' }), []);
	const [integrationEmail, setIntegrationEmail] = useState<string>('');

	useAutoFetch({
		selector: mainIntegrationsSelectorsPack.selectAsyncRemoteExternalServicesMap.selectDidInvalidate,
		fetch: () => fetchIntegrationsData(),
	});

	useEffect(() => {
		fetchData();
	}, [variant]);

	const fetchData = async () => {
		setIsFetching(true);

		if (isAdd) {
			setAccount(new securityApi.package.ExternalSystemAccount());
		} else if (isEdit) {
			const account = await securityApi.externalSystem.fetchExternalSystemAccountByID(accountID);

			scope.adminPassword = decryptVernam(account.AdminPassword, ticketUID);
			account.AdminPassword = NON_UPDATED_PASSWORD;
			setAccount(account);

			const email = await securityApi.directory.assureIntegrationEmailCreated();

			setIntegrationEmail(email);
		}

		setIsFetching(false);
	};

	const handleSubmitForm = (account: ExternalSystemAccount, isAuthRequested?: boolean) => {
		const notCreatedEmail = authMethodCode === AuthMethodCode.EMAIL && !Boolean(integrationEmail);

		if (notCreatedEmail) {
			securityApi.directory.assureIntegrationEmailCreated().then(email => setIntegrationEmail(email));
		}

		const ssi = ssiMap[ssiGUID];
		const authOptions = isAuthRequested
			? createAuthOptions({ account, ssi, adminPassword: scope.adminPassword, postActionURL })
			: undefined;

		if (isAdd) {
			account.ExternalSSInstanceID = ssi.ID;
			addExternalSystemAccount({
				account,
				authOptions,
				onComplete: account => onAddAccount(account),
			});
		} else if (isEdit) {
			if (isAuthRequested) {
				authorizeExternalSystemAccount(authOptions);
			} else {
				if (!detectIsChangedPassword(account.AdminPassword)) {
					account.AdminPassword = '';
				}

				updateExternalSystemAccount(account);
			}
		}

		!notCreatedEmail && !embedded && onClose();
	};

	const handleCopyGUID = () => emitActionMessage('Идентификатор скопирован 😀', 'success');

	const ssiID = ssiMap[ssiGUID]?.ID;
	const authMethodCode = isFetching ? null : getAuthMethodCode(ssiID, resMap);

	return (
		<XUpdateForm
			{...rest}
			account={account}
			authMethodCode={authMethodCode}
			embedded={embedded}
			integrationEmail={integrationEmail}
			isFetching={isFetching || isFetchingFromState}
			onClose={onClose}
			onCopyGUID={handleCopyGUID}
			onSubmitForm={handleSubmitForm}
			resMap={resMap}
			ssiGUID={ssiGUID}
			ssiMap={ssiMap}
			variant={variant}
		/>
	);
};

UpdateForm.defaultProps = {
	onAddAccount: () => {},
	onClose: () => getDrawer().close(),
};

type CreateAuthorizationOptions = {
	account: ExternalSystemAccount;
	ssi: SubsystemInstance;
	adminPassword: string;
	postActionURL: string;
};

function createAuthOptions(options: CreateAuthorizationOptions): AuthOptions {
	const {
		account,
		ssi,
		adminPassword,
		postActionURL = encodeURIComponent(`${location.origin}${ROUTE_MAP.TENANT_ACCOUNT}/integrations`),
	} = options;

	return {
		externalSystemAccountID: account.ID,
		subsystemInstanceID: ssi.ID,
		subsystemInstanceGUID: ssi.GUID,
		taxCode: account.TaxCode,
		additionalTaxCode: account.AdditionalTaxCode,
		loginAPI: account.AdminUser,
		passwordAPI: detectIsChangedPassword(account.AdminPassword)
			? account.AdminPassword
			: adminPassword || account.AdminPassword,
		postActionURL,
	};
}

function detectIsChangedPassword(password: string) {
	return password !== NON_UPDATED_PASSWORD;
}

type DrawerOptions = UpdateFormProps;

function openUpdateExternalSystemAccountFormDrawer(options: DrawerOptions) {
	getDrawer().open({
		overlay: true,
		title: '',
		footer: null,
		content: <UpdateForm {...options} />,
	});
}

export { openUpdateExternalSystemAccountFormDrawer, UpdateForm as UpdateExternalSystemAccountForm };
