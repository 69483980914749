import { FetchIntegrationsDataReturnType } from '@core/api/security';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
} from '@flux';
import { LastImportedAccountData, LastImportedFileData } from '@integrations/actions';
import { types } from '@integrations/actions/types';
import { createDefaultPeriod } from '@utils/date';

export type MainIntegrationsState = {
	cashManagementSystems: StoreAsyncItem<Array<CashManagementSystem>>;
	cmsAccountsTextFilter: string;
	externalSystemAccounts: StoreAsyncItem<Array<ExternalSystemAccount>>;
	importDateRange: DateRange;
	lastAccountStatementImport: AccountStatementImport;
	lastAccountStatementImports: StoreAsyncItem<Array<AccountStatementImport>>;
	lastImportedAccountData: LastImportedAccountData;
	lastImportedFileData: LastImportedFileData;
	remoteExternalServicesMap: StoreAsyncItem<Record<string, RemoteExternalService>>;
	searchText: string;
	selectedCmsID: number;
	selectedExternalSystemAccountID: number;
	sourceTypes: StoreAsyncItem<Array<FundsRegisterSourceType>>;
	subSystemInstances: StoreAsyncItem<Array<SubsystemInstance>>;
};

const initialState: MainIntegrationsState = {
	cashManagementSystems: createAsyncInitialState([]),
	cmsAccountsTextFilter: '',
	externalSystemAccounts: createAsyncInitialState([]),
	importDateRange: createDefaultPeriod('quarter'),
	lastAccountStatementImport: null,
	lastAccountStatementImports: createAsyncInitialState([]),
	lastImportedAccountData: {
		legalEntityName: '',
		registerNumber: '',
	},
	lastImportedFileData: {
		appearance: null,
		fundsRegisterID: -1,
		name: '',
	},
	remoteExternalServicesMap: createAsyncInitialState({}),
	searchText: '',
	selectedCmsID: null,
	selectedExternalSystemAccountID: null,
	sourceTypes: createAsyncInitialState([]),
	subSystemInstances: createAsyncInitialState([]),
};

const mainIntegrationsReducer = createReducer<MainIntegrationsState>(initialState, {
	[types.ADD_EXTERNAL_SYSTEM_ACCOUNT]: (action: AsyncAction<ExternalSystemAccount>, state) => {
		return {
			externalSystemAccounts: invalidateStateFromAction(action, state.externalSystemAccounts),
		};
	},
	[types.CLEAR_LAST_IMPORTED_ACCOUNT_DATA]: () => {
		return {
			lastImportedAccountData: { ...initialState.lastImportedAccountData },
		};
	},
	[types.CLEAR_LAST_IMPORTED_FILE_DATA]: () => {
		return {
			lastImportedFileData: { ...initialState.lastImportedFileData },
		};
	},
	[types.FETCH_CASH_MANAGEMENT_SYSTEMS]: (action: AsyncAction<Array<CashManagementSystem>>, state) => {
		return {
			cashManagementSystems: checkAsyncAction(action, state.cashManagementSystems),
		};
	},
	[types.FETCH_EXTERNAL_SYSTEM_ACCOUNTS]: (action: AsyncAction<Array<ExternalSystemAccount>>, state) => {
		return {
			externalSystemAccounts: checkAsyncAction(action, state.externalSystemAccounts),
		};
	},
	[types.FETCH_INTEGRATIONS_DATA]: (action: AsyncAction<FetchIntegrationsDataReturnType>, state) => {
		return {
			subSystemInstances: checkAsyncAction(action, state.subSystemInstances, x => x.subSystemInstances),
			remoteExternalServicesMap: checkAsyncAction(
				action,
				state.remoteExternalServicesMap,
				x => x.remoteExternalServicesMap,
			),
		};
	},
	[types.FETCH_LAST_ACCOUNT_STATEMENT_IMPORTS]: (action: AsyncAction<Array<AccountStatementImport>>, state) => {
		return {
			lastAccountStatementImports: checkAsyncAction(action, state.lastAccountStatementImports),
		};
	},
	[types.FETCH_SOURCE_TYPES]: (action: AsyncAction<Array<FundsRegisterSourceType>>, state) => {
		return {
			sourceTypes: checkAsyncAction(action, state.sourceTypes),
		};
	},
	[types.INVALIDATE_LAST_ACCOUNT_STATEMENT_IMPORTS]: (action: StaticAction<AccountStatementImport>, state) => {
		return {
			lastAccountStatementImports: invalidateStateFromAction(action, state.lastAccountStatementImports),
		};
	},
	[types.REMOVE_EXTERNAL_SYSTEM_ACCOUNT]: (action: AsyncAction<boolean>, state) => {
		return {
			externalSystemAccounts: invalidateStateFromAction(action, state.externalSystemAccounts),
		};
	},
	[types.SET_CMS_ACCOUNTS_TEXT_FILTER]: (action: StaticAction<string>) => {
		return {
			cmsAccountsTextFilter: action.value,
		};
	},
	[types.SET_IMPORT_DATE_RANGE]: (action: StaticAction<DateRange>) => {
		return {
			importDateRange: action.value,
		};
	},
	[types.SET_LAST_ACCOUNT_STATEMENT_IMPORT]: (action: StaticAction<AccountStatementImport>) => {
		return {
			lastAccountStatementImport: action.value,
		};
	},
	[types.SET_LAST_IMPORTED_ACCOUNT_DATA]: (action: StaticAction<LastImportedAccountData>) => {
		return {
			lastImportedAccountData: action.value,
		};
	},
	[types.SET_LAST_IMPORTED_FILE_DATA]: (action: StaticAction<LastImportedFileData>) => {
		return {
			lastImportedFileData: action.value,
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
		};
	},
	[types.SET_SELECTED_CMS_ID]: (action: StaticAction<number>) => {
		return {
			selectedCmsID: action.value,
		};
	},
	[types.SET_SELECTED_EXTERNAL_SYSTEM_ACCOUNT_ID]: (action: StaticAction<number>) => {
		return {
			selectedExternalSystemAccountID: action.value,
		};
	},
	[types.UPDATE_EXTERNAL_SYSTEM_ACCOUNT]: (action: AsyncAction<ExternalSystemAccount>, state) => {
		return {
			externalSystemAccounts: invalidateStateFromAction(action, state.externalSystemAccounts),
		};
	},
});

export default mainIntegrationsReducer;
