import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { GoalStep } from '@core/hooks/use-goals';
import { ROUTE_MAP } from '@core/routes/urls';
import { SelectDate } from '@funds-registers/components/cms-import-period-select';
import { FormAppearance as SelectDateFormAppearance } from '@funds-registers/components/cms-import-period-select/model';
import { FileAppearance } from '@funds-registers/components/import-files-form/model';
import { DrawerZone } from '@funds-registers/models';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import {
	selectLastAccountStatementImport,
	selectLastImportedAccountData,
	selectLastImportedFileData,
} from '@integrations/selectors';
import { mainPaymentsActionsPack } from '@payments/actions';
import { ErrorIcon } from '@ui/icons/error';
import { SuccessIcon } from '@ui/icons/success';
import { extractFundsRegisterIdAfterImport, getDrawer, replaceRegisterNumberByAsterisk } from '@utils';
import { plural } from '@utils/plural';
import {
	AddButton,
	ButtonLayout,
	ContentLayout,
	ControlsLayout,
	DefaultButton,
	Detail,
	IconLayout,
	Root,
	Title,
	TitleLayout,
	WrapLayout,
} from './styled';

export type XImportResultProps = {
	zone: DrawerZone;
	status: AsyncProgressStatus;
	fundsRegisterStatisticsMap: ReturnType<typeof mainFundsRegistersSelectorsPack.selectFundsRegisterStatisticsMap>;
	lastImportedAccountData: ReturnType<typeof selectLastImportedAccountData>;
	lastImportedFileData: ReturnType<typeof selectLastImportedFileData>;
	lastAccountStatementImport: ReturnType<typeof selectLastAccountStatementImport>;
	setFundsRegisterID: typeof mainPaymentsActionsPack.setFundsRegisterID;
	onClose: () => void;
	onAddAccount: () => void;
	onAddImportFile: () => void;
	onRepeat: () => void;
};

const XImportResult: React.FC<XImportResultProps> = props => {
	const {
		zone,
		status,
		fundsRegisterStatisticsMap,
		lastImportedAccountData,
		lastImportedFileData,
		lastAccountStatementImport,
		setFundsRegisterID,
		onClose,
		onAddAccount,
		onAddImportFile,
		onRepeat,
	} = props;
	const history = useHistory();

	useLayoutEffect(() => {
		getDrawer().setContent({
			footer: null,
			goalStepOnExit: goalStepOnExitMap[zone] && goalStepOnExitMap[zone][status.status],
		});
	}, []);

	const isImportProgress = zone === DrawerZone.IMPORT_CMS_PROGRESS || zone === DrawerZone.REFRESH_CMS_PROGRESS;
	const isRefreshResult = zone === DrawerZone.REFRESH_CMS_RESULT;

	const isSuccess = status.status === 'SUCCESS';
	const isFailed = status.status === 'FAILED';

	const isExcel = lastImportedFileData?.appearance === FileAppearance.EXCEL;

	const renderSelectDate = () => {
		if (isImportProgress) {
			return (
				<SelectDate
					appearance={
						zone === DrawerZone.IMPORT_CMS_PROGRESS
							? SelectDateFormAppearance.IMPORT_EXTERNAL
							: SelectDateFormAppearance.REFRESH_PAYMENTS
					}
					disabled
				/>
			);
		}
		return null;
	};

	const renderTitle = () => {
		if (isSuccess) {
			switch (zone) {
				case DrawerZone.IMPORT_CMS_RESULT:
					return `Счёт ${replaceRegisterNumberByAsterisk(lastImportedAccountData.registerNumber)} импортирован`;
				case DrawerZone.REFRESH_CMS_RESULT:
					return `Операции по счёту ${replaceRegisterNumberByAsterisk(
						lastImportedAccountData.registerNumber,
					)} обновлены`;
				case DrawerZone.IMPORT_FILE_RESULT:
					return `Файл ${lastImportedFileData.name} успешно импортирован`;
			}
		}
		if (status.status === 'FAILED') {
			return 'В процессе импорта возникла ошибка';
		}
		if (status.status === 'FINISHED_WITH_WARNING') {
			return 'В процессе импорта возникли проблемы';
		}
		if (status.status === 'DUPLICATE_REQUEST') {
			return 'Процесс импорта уже был запущен';
		}
	};

	const goToAccount = () => {
		const fundsRegisterID =
			zone === DrawerZone.IMPORT_FILE_RESULT
				? lastImportedFileData.fundsRegisterID
				: extractFundsRegisterIdAfterImport(fundsRegisterStatisticsMap, lastImportedAccountData.registerNumber);
		setFundsRegisterID(fundsRegisterID);
		history.push(ROUTE_MAP.PAYMENTS);
		onClose();
	};

	const renderControls = () => {
		if (status.status === 'SUCCESS') {
			if (zone === DrawerZone.IMPORT_FILE_RESULT) {
				return (
					<ControlsLayout flexCenter={isExcel}>
						<ButtonLayout>
							<AddButton goalStep={GoalStep.IMPORT_EXCEL_YET} label='Импортировать ещё' onClick={onAddImportFile} />
						</ButtonLayout>
						{!isExcel && (
							<ButtonLayout>
								<DefaultButton label='Перейти к счёту' onClick={goToAccount} />
							</ButtonLayout>
						)}
					</ControlsLayout>
				);
			}
			return (
				<ControlsLayout flexCenter={isRefreshResult}>
					{zone === DrawerZone.IMPORT_CMS_RESULT && (
						<ButtonLayout>
							<AddButton label='Добавить ещё' onClick={onAddAccount} />
						</ButtonLayout>
					)}
					<ButtonLayout>
						<DefaultButton
							goalStep={isRefreshResult && GoalStep.SYNC_GO_TO_ACCOUNT}
							label='Перейти к счёту'
							onClick={goToAccount}
						/>
					</ButtonLayout>
				</ControlsLayout>
			);
		}
		return (
			<ControlsLayout>
				<ButtonLayout>
					<AddButton goalStep={isRefreshResult && GoalStep.SYNC_RETRY_IMPORT} label='Повторить' onClick={onRepeat} />
				</ButtonLayout>
				<ButtonLayout>
					<DefaultButton goalStep={isRefreshResult && GoalStep.SYNC_CANCEL_ON_ERROR} label='Отмена' onClick={onClose} />
				</ButtonLayout>
			</ControlsLayout>
		);
	};

	const renderSuccessView = () => {
		const addedItemsCount = lastAccountStatementImport?.AddedItemsCount || 0;
		const changedItemsCount = lastAccountStatementImport?.ChangedItemsCount || 0;
		const removedItemsCount = lastAccountStatementImport?.RemovedItemsCount || 0;
		const pluralTitles = ['платёж', 'платежа', 'платежей'] as [string, string, string];

		return (
			<WrapLayout>
				<IconLayout>
					<SuccessIcon color='accent' size={100} />
				</IconLayout>
				<TitleLayout>
					<Title>{renderTitle()}</Title>
					{!isExcel && (
						<>
							<Detail>
								Добавлено {addedItemsCount} {plural({ count: addedItemsCount, titles: pluralTitles })}
							</Detail>
							<Detail>
								Изменено {changedItemsCount} {plural({ count: changedItemsCount, titles: pluralTitles })}
							</Detail>
							<Detail>
								Удалено {removedItemsCount} {plural({ count: removedItemsCount, titles: pluralTitles })}
							</Detail>
						</>
					)}
				</TitleLayout>
				{renderControls()}
			</WrapLayout>
		);
	};

	const renderFailedView = () => {
		return (
			<WrapLayout>
				<IconLayout>
					<ErrorIcon color={isFailed ? 'alarm' : 'warning'} size={100} />
				</IconLayout>
				<TitleLayout>
					<Title>{renderTitle()}</Title>
					{status.message && <Detail>{status.message}</Detail>}
				</TitleLayout>
				{renderControls()}
			</WrapLayout>
		);
	};

	return (
		<Root>
			{renderSelectDate()}
			<ContentLayout>{isSuccess ? renderSuccessView() : renderFailedView()}</ContentLayout>
		</Root>
	);
};

const goalStepOnExitMap = {
	[DrawerZone.IMPORT_FILE_RESULT]: {
		['FAILED']: GoalStep.IMPORT_EXCEL_FAIL_EXIT,
		['SUCCESS']: GoalStep.IMPORT_EXCEL_SUCCESS_EXIT,
	},
	[DrawerZone.REFRESH_CMS_RESULT]: {
		['FAILED']: GoalStep.SYNC_EXIT_ERROR,
		['SUCCESS']: GoalStep.SYNC_EXIT_SUCCESS,
	},
};

export { XImportResult };
