import { createTypes } from '@flux';

type ActionTypes = {
	ADD_FUNDS_REGISTER: string;
	CLEAR_FUNDS_REGISTER_ID_FOR_AUTO_INSERT: string;
	CLEAR_FUNDS_REGISTER_ID_FOR_REFRESH: string;
	FETCH_BANKS: string;
	FETCH_CMS_ACCOUNTS: string;
	FETCH_FUNDS_REGISTER_PURPOSES: string;
	FETCH_FUNDS_REGISTER_STATISTICS: string;
	FETCH_IS_BALANCE_HIDDEN: string;
	IMPORT_FUNDS_REGISTER_FILES_COMPLETED: string;
	INVALIDATE_FUNDS_REGISTER_STATISTICS: string;
	RECALCULATE_FUNDS_REGISTER_BALANCES: string;
	REFRESH_CMS_ACCOUNTS: string;
	REMOVE_FUNDS_REGISTER: string;
	RUN_FUNDS_REGISTER_STATISTICS_INVALIDATION_EFFECT: string;
	SET_FILTER_BY_TEXT: string;
	SET_FUNDS_REGISTER_ACCESS: string;
	SET_FUNDS_REGISTER_ID_FOR_AUTO_INSERT: string;
	SET_FUNDS_REGISTER_ID_FOR_REFRESH: string;
	SET_IS_BALANCE_HIDDEN: string;
	SET_IS_INCLUDE_ARCHIVED: string;
	SET_SELECTED_CMS: string;
	SET_VIRTUAL_LIST_GROUP: string;
	UPDATE_FUNDS_REGISTER: string;
};

export const types = createTypes<ActionTypes>(
	[
		'ADD_FUNDS_REGISTER',
		'CLEAR_FUNDS_REGISTER_ID_FOR_AUTO_INSERT',
		'CLEAR_FUNDS_REGISTER_ID_FOR_REFRESH',
		'FETCH_BANKS',
		'FETCH_CMS_ACCOUNTS',
		'FETCH_FUNDS_REGISTER_PURPOSES',
		'FETCH_FUNDS_REGISTER_STATISTICS',
		'FETCH_IS_BALANCE_HIDDEN',
		'IMPORT_FUNDS_REGISTER_FILES_COMPLETED',
		'INVALIDATE_FUNDS_REGISTER_STATISTICS',
		'RECALCULATE_FUNDS_REGISTER_BALANCES',
		'REFRESH_CMS_ACCOUNTS',
		'REMOVE_FUNDS_REGISTER',
		'RUN_FUNDS_REGISTER_STATISTICS_INVALIDATION_EFFECT',
		'SET_FILTER_BY_TEXT',
		'SET_FUNDS_REGISTER_ACCESS',
		'SET_FUNDS_REGISTER_ID_FOR_AUTO_INSERT',
		'SET_FUNDS_REGISTER_ID_FOR_REFRESH',
		'SET_IS_BALANCE_HIDDEN',
		'SET_IS_INCLUDE_ARCHIVED',
		'SET_VIRTUAL_LIST_GROUP',
		'UPDATE_FUNDS_REGISTER',
	],
	'FUNDS_REGISTERS',
);
