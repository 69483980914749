import { createTypes } from '@flux';

type ActionTypes = {
	ADD_EXTERNAL_SYSTEM_ACCOUNT: string;
	AUTHORIZE_EXTERNAL_SYSTEM_ACCOUNT: string;
	CLEAR_LAST_IMPORTED_ACCOUNT_DATA: string;
	CLEAR_LAST_IMPORTED_FILE_DATA: string;
	FETCH_CASH_MANAGEMENT_SYSTEMS: string;
	FETCH_EXTERNAL_SYSTEM_ACCOUNTS: string;
	FETCH_INTEGRATIONS_DATA: string;
	FETCH_LAST_ACCOUNT_STATEMENT_IMPORTS: string;
	FETCH_SOURCE_TYPES: string;
	IMPORT_ACCOUNT_FROM_CMS: string;
	INVALIDATE_LAST_ACCOUNT_STATEMENT_IMPORTS: string;
	REMOVE_EXTERNAL_SYSTEM_ACCOUNT: string;
	SET_CMS_ACCOUNTS_TEXT_FILTER: string;
	SET_IMPORT_DATE_RANGE: string;
	SET_LAST_ACCOUNT_STATEMENT_IMPORT: string;
	SET_LAST_IMPORTED_ACCOUNT_DATA: string;
	SET_LAST_IMPORTED_FILE_DATA: string;
	SET_SEARCH_TEXT: string;
	SET_SELECTED_CMS_ID: string;
	SET_SELECTED_EXTERNAL_SYSTEM_ACCOUNT_ID: string;
	UPDATE_EXTERNAL_SYSTEM_ACCOUNT: string;
};

export const types = createTypes<ActionTypes>(
	[
		'ADD_EXTERNAL_SYSTEM_ACCOUNT',
		'AUTHORIZE_EXTERNAL_SYSTEM_ACCOUNT',
		'CLEAR_LAST_IMPORTED_ACCOUNT_DATA',
		'CLEAR_LAST_IMPORTED_FILE_DATA',
		'FETCH_CASH_MANAGEMENT_SYSTEMS',
		'FETCH_EXTERNAL_SYSTEM_ACCOUNTS',
		'FETCH_INTEGRATIONS_DATA',
		'FETCH_LAST_ACCOUNT_STATEMENT_IMPORTS',
		'FETCH_SOURCE_TYPES',
		'IMPORT_ACCOUNT_FROM_CMS',
		'INVALIDATE_LAST_ACCOUNT_STATEMENT_IMPORTS',
		'REMOVE_EXTERNAL_SYSTEM_ACCOUNT',
		'SET_CMS_ACCOUNTS_TEXT_FILTER',
		'SET_IMPORT_DATE_RANGE',
		'SET_LAST_ACCOUNT_STATEMENT_IMPORT',
		'SET_LAST_IMPORTED_ACCOUNT_DATA',
		'SET_LAST_IMPORTED_FILE_DATA',
		'SET_SEARCH_TEXT',
		'SET_SELECTED_CMS_ID',
		'SET_SELECTED_EXTERNAL_SYSTEM_ACCOUNT_ID',
		'UPDATE_EXTERNAL_SYSTEM_ACCOUNT',
	],
	'INTEGRATIONS',
);
